// hooks/useCheckDraftMode.ts
import { useEffect } from "react";
import { useEditState } from "tinacms/dist/react";

const useCheckDraftMode = () => {
  const { edit } = useEditState();
  useEffect(() => {
    if (!edit) return;
    const checkDraftMode = async () => {
      try {
        const response = await fetch("/api/preview/check", {
          credentials: "include",
        });
        if (response.ok) {
          const { isDraftMode } = await response.json();
          if (!isDraftMode) {
            // Get auth token
            const token = localStorage.getItem("tinacms-auth");
            let tokenDict = {
              id_token: "",
              access_token: "",
              refresh_token: "",
            };
            if (token) {
              try {
                tokenDict = JSON.parse(token);
              } catch (e) {}
            }
            console.log("token", token);
            // If not in draft mode, redirect to trigger draft mode
            window.location.href = `/api/preview/enter?slug=${encodeURIComponent(
              window.location.pathname
            )}&clientID=${process.env.NEXT_PUBLIC_TINA_CLIENT_ID || ""}&token=${
              tokenDict?.id_token || ""
            }`;
          }
        }
      } catch (error) {
        console.error("Failed to check draft mode:", error);
      }
    };
    checkDraftMode();
  }, [edit]);
};

export default useCheckDraftMode;
