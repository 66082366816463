import styled, {
  css as css_,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemedCssFunction,
} from "styled-components";
import { mobile } from "../hooks";
import { colors } from "./colors";
import { Mode } from "./theme";

const css = css_ as ThemedCssFunction<{}>;

export const font = (mode: Mode) => {
  const font = css`
    font-family: "Inter", sans-serif;
  `;
  return {
    display1: css`
      ${font};
      font-size: 80px;
      line-height: 84px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    display2: css`
      ${font};
      font-size: 72px;
      line-height: 76px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    display3: css`
      ${font};
      font-size: 54px;
      line-height: 60px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline1: css`
      ${font};
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline1_a: css`
      ${font};
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline1_b: css`
      ${font};
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline1_c: css`
      ${font};
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline2: css`
      ${font};
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -2%;
      font-weight: 700;
    `,

    headline3: css`
      ${font};
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -1.4%;
      font-weight: 700;
    `,

    headline4: css`
      ${font};
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -1.4%;
      font-weight: 700;
    `,

    headline4_a: css`
      ${font};
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -1.4%;
      font-weight: 700;
    `,

    headline4_b: css`
      ${font};
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -1.4%;
      font-weight: 700;
    `,
    headline4_c: css`
      ${font};
      font-size: 16px;
      line-height: 14px;
      letter-spacing: -1.4%;
      font-weight: 700;
    `,
    uppercase: css`
      ${font};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0%;
      font-weight: 700;
      text-transform: uppercase;
    `,

    body: css`
      ${font};
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0%;
      font-weight: 400;
      @media (max-width: 520px) {
        font-size: 14px;
        line-height: 20px;
      }
    `,

    caption: css`
      ${font};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0%;
      font-weight: 400;
    `,

    button: css`
      ${font};
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0%;
      font-weight: 400;
    `,
  };
};
