import { useRef } from "react";

export function Fonts() {
  const ref = useRef<HTMLLinkElement>();
  return (
    <>
      <link rel="DNS-prefetch" href="//fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />

      <link
        rel="stylesheet"
        media="print"
        ref={(e) => {
          if (e) ref.current = e;
        }}
        onLoad={(e) => {
          if (ref.current) {
            ref.current.onload = null;
            ref.current.removeAttribute("media");
          }
        }}
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
      />

      <noscript>
        <link rel="stylesheet" href="(font CSS URL here)" />
      </noscript>
    </>
  );
}
