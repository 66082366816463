import { css } from "../css";
import { colors } from "../colors";
import { Mode } from "../theme";

export const 
buttonTypes = (mode:Mode)=>({
  primary: css`
    background-color: ${colors(mode)["background/bg-0"]};
    color: ${colors(mode)["background/bg-0"]};
    &:hover{
      background-color: ${colors(mode)["background/bg-1"]};
      >svg{
        fill: ${colors(mode)["background/bg-0"]};
      }
    }
    &:active {
      background-color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]};
      }
    }
    &:disabled{
      background-color: ${colors(mode)["elements/gray-1"]};
      >svg{
        fill: ${colors(mode)["elements/gray-2"]};
      }
    }
  `,

  secondary: css`
    background-color: #ffffff;
    color: ${colors(mode)["text/primary"]};
    &:hover{
      background-color: ${colors(mode)["background/bg-0"]};
      color: #174AFF;
      >svg{
        fill: #174AFF;
      }
    }
    &:active {
      background-color: ${colors(mode)["background/bg-0"]};
      color: #002FD3;
      >svg{
        fill: #002FD3;
      }
    }
    &:disabled{
      background-color: #ffffff;
      >svg{
        fill: ${colors(mode)["text/primary"]};
      }
    }
  `,

  outlined: css`
    color: ${colors(mode)["text/primary"]};
    background-color:  ${colors(mode)["background/bg-1"]};
    border: 1px solid ${colors(mode)["text/primary"]};
    :hover{
      border-color: ${colors(mode)["text/secondary"]};
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]};
      }
    }
    :active {
      border-color: ${colors(mode)["text/secondary"]};
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]};
      }
    }
    :disabled{
      border-color: ${colors(mode)["text/primary"]};
      color: ${colors(mode)["text/primary"]};
      >svg{
        fill: ${colors(mode)["text/primary"]};
      }
    }
  `,
  
  subtle: css`
    color: ${colors(mode)["text/primary"]};
    background-color:  ${colors(mode)["background/bg-1"]};
    border: 1px solid ${colors(mode)["elements/gray-1"]};
    :hover {
      border-color: ${colors(mode)["elements/gray-1"]};
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]};
      }
    }
    :active {
      border-color: ${colors(mode)["elements/gray-1"]};
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]};
      }
    }
    :disabled{
      border-color: ${colors(mode)["text/primary"]};
      color: ${colors(mode)["elements/gray-1"]};
      >svg{
        fill: ${colors(mode)["elements/gray-1"]};
      }
    }
  `,
  text: css`
    color: ${colors(mode)["text/primary"]};
    background-color:  ${colors(mode)["background/bg-1"]};
    :hover {
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]} !important;
      }
    }
    :active {
      color: ${colors(mode)["text/secondary"]};
      >svg{
        fill: ${colors(mode)["text/secondary"]} !important;
      }
    }
    :disabled {
      color: ${colors(mode)["elements/gray-1"]};
      >svg{
        fill: ${colors(mode)["elements/gray-1"]} !important;
      }
    }
  `
}),

buttonSizes = {
  small: css`
    height: 32px;
    font-size: 10px;
  `,
  medium: css`
    height: 40px;
    font-size: 12px;
  `,
  large: css`
    height: 56px;
    font-size: 14px;
  `
}