export function toString(
  value: any,
  options = {} as { includeImageAlt: boolean }
) {
  const { includeImageAlt = true } = options;
  return one(value, includeImageAlt);
}

function one(value: any | any[], includeImageAlt: boolean): any {
  return (
    (node(value) &&
      (("text" in value && value.text) ||
        (includeImageAlt && "alt" in value && value.alt) ||
        ("children" in value && all(value.children, includeImageAlt)))) ||
    (Array.isArray(value) && all(value, includeImageAlt)) ||
    ""
  );
}

function all(values: any[], includeImageAlt: boolean): any {
  /** @type {Array<string>} */
  const result = [];
  let index = -1;

  while (++index < values.length) {
    result[index] = one(values[index], includeImageAlt);
  }

  return result.join("");
}

function node(value: Node) {
  return Boolean(value && typeof value === "object");
}
