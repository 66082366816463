export function HeadersPWA() {
  return (
    <>
      <meta name="application-name" content="OPT Industries" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="OPT Industries" />
      <meta name="description" content="OPT Industries Official" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/icons/browserconfig.xml" />
      <meta name="msapplication-TileColor" content="#2e2e2e" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#2e2e2e" />

      <link rel="apple-touch-icon" href="/icons/icon-512-512.png" />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/icons/icon-152-152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/icon-180-180.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href="/icons/icon-167-167.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/icon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/icon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/icons/icon-512-512.png" color="#2e2e2e" />
      <link rel="shortcut icon" href="/favicon.ico" />

      <link
        rel="apple-touch-startup-image"
        href="/images/icon-2048-2732.png"
        sizes="2048x2732"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-1668-2224.png"
        sizes="1668x2224"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-1536-2048.png"
        sizes="1536x2048"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-1125-2436.png"
        sizes="1125x2436"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-1242-2208.png"
        sizes="1242x2208"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-750-1334.png"
        sizes="750x1334"
      />
      <link
        rel="apple-touch-startup-image"
        href="/images/icon-640-1136.png"
        sizes="640x1136"
      />
    </>
  );
}
