import { useEffect, useMemo, useRef, useState } from "react";

export function useInViewport<T extends Element = HTMLDivElement>() {
  const ref = useRef<T | null>(null);
  const [visible, setIsIntersecting] = useState(false);

  // const observer = useMemo(
  //   () =>
  //     new IntersectionObserver(([entry]) =>
  //       setIsIntersecting(entry.isIntersecting),
  //     ),
  //   [],
  // );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const observer = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      );

      if (ref?.current) observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return { visible, ref };
}
