import { colors } from './colors'
import { buttonTypes, buttonSizes } from './inputs/buttons';
import { font } from './font'
import { css } from './css';

export type Mode = 'dark-mode'|'light-mode'
export const theme = (mode:Mode = 'dark-mode')=>({
  id: "T-001",
  name: 'main',
  shadow: css`box-shadow: 3px 3px 3px rgba(0,0,0,0.15);`,
  colors: colors(mode),
  buttonTypes: buttonTypes(mode),
  buttonSizes,
  font: font(mode)
}) as const;

export type Theme = typeof theme;