import {
  useState,
  useEffect,
  MutableRefObject,
  useRef,
  useCallback,
  RefObject,
} from "react";
import { useRouter } from "next/router";
export * from "./style-functions";
export * from "./useInViewport";

export function getWindowDimensions() {
  const {
    screen: { width, height },
    devicePixelRatio,
  } = window;

  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowDimensions(getWindowDimensions());

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function usePrevious<T>(
  value: T
): MutableRefObject<T | undefined>["current"] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useLayout() {
  const { width, height } = useWindowDimensions();

  const device = width <= 414 ? "mobile" : width <= 912 ? "tablet" : "desktop";

  return !width || width === 0 ? "desktop" : device;
}

interface Size {
  width: number;
  height: number;
}

interface Ref {
  ref: RefObject<HTMLDivElement>;
}

// Hook to get the size of a ref and to update it when the ref changes
export const useSize = (props?: {
  noObserver?: boolean;
  onLoad?: boolean;
  applyToFirstChild?: boolean;
}): Size & Ref => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const updateSize = useCallback(() => {
    if (ref.current) {
      if (props?.applyToFirstChild) {
        setWidth(ref.current?.children?.[0]?.clientWidth);
        setHeight(ref.current?.children?.[0]?.clientHeight);
      } else {
        setWidth(ref.current.clientWidth);
        setHeight(ref.current.clientHeight);
      }
    }
  }, [ref.current]);

  useEffect(() => {
    if (!props?.onLoad || !width) updateSize();
  }, [updateSize]);

  useEffect(() => {
    if (ref.current && !props?.noObserver) {
      const observer = new ResizeObserver((entries) => {
        updateSize();
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
    return () => {};
  }, [ref.current, updateSize]);

  return { ref, width, height };
};

export function useLang() {
  const locale = useRouter().locale;

  return (locale === "pseudo" ? "en" : locale) || "en";
}
