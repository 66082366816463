export * from "./theme";
export * from "./hooks";
export * from "./fonts";
export * from "./tina";
export * from "./headers";

export const isDefault = (v: any) => v === null || v === undefined;
export * from "./filters";
export * from "./ast";
import { toString } from "./ast";
interface ImageData {
  height: number | undefined;
  width: number | undefined;
}

export const getImageData = (file: File | Blob): Promise<ImageData> => {
  return new Promise((resolve) => {
    if (file.type.split("/")?.[0] === "image") {
      let img = new Image();
      let objectUrl = URL.createObjectURL(file);
      img.onload = (event: Event) => {
        let image = event.target as HTMLImageElement;
        resolve({ height: image.height, width: image.width });
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    } else {
      resolve({ height: undefined, width: undefined });
    }
  });
};

export function getRichText(t: string) {
  return {
    type: "root",
    children: [{ type: "p", children: [{ type: "text", text: t }] }],
  };
}

export function getPlainText(t: JSON): string {
  return toString(t) || "";
}
