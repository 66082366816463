import ErrorBoundary from "@/components/ErrorBoundary";
import { GlobalStyle, theme, useLayout } from "@/lib";
import useCheckDraftMode from "@/lib/hooks/use-check-draft-mode";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import type {} from "styled-components/cssprop";
import * as gtag from "../lib/gtag";
import "./globals.css";
const isProduction = process.env.NODE_ENV === "production";

const App = ({
  Component,
  pageProps,
  __N_PREVIEW = false,
  ...rest
}: AppProps & {
  pageProps?: { data?: { global?: Global } };
  __N_PREVIEW?: boolean;
}) => {
  const layout = useLayout();
  const [mode] = useState<"dark-mode" | "light-mode">("dark-mode");
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  useCheckDraftMode();

  const slug = typeof window !== "undefined" ? window.location.pathname : "/";
  return (
    <>
      {__N_PREVIEW && (
        <div className="sticky top-[calc(100vh-5rem)] pointer-events-none border border-white/50 backdrop-blur-md -translate-x-[50%] bg-black/50 left-[50%] w-[250px] rounded-xl p-2 font-normal text-sm z-[101] opacity-90">
          <span className="font-normal text-sm flex items-center w-full justify-center">
            You are in a preview-mode
          </span>
          <span className="font-normal text-sm flex items-center w-full justify-center">
            Logout in admin panel to exit
          </span>
          <a
            href={`/api/preview/exit?slug=${slug}`}
            className="font-normal text-sm hidden"
          >
            <span className="font-normal text-sm flex items-center w-full justify-center">
              Click here to exit
            </span>
          </a>
        </div>
      )}
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {isProduction && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      <NextThemesProvider
        attribute="class"
        enableSystem={false}
        defaultTheme="dark"
        disableTransitionOnChange
      >
        <ThemeProvider {...{ theme: { ...theme(mode), layout } }}>
          <ErrorBoundary>
            <GlobalStyle
              backgroundColor={pageProps?.data?.global?.backgroundColor}
            />
            <Component {...pageProps} />
            <VercelAnalytics />
          </ErrorBoundary>
        </ThemeProvider>
      </NextThemesProvider>
    </>
  );
};

export default App;
