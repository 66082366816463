import { createGlobalStyle, css } from "styled-components";
import { desktop, mobile, tabletDown } from "../hooks";

const fonts = css`
  body > * {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    h1 {
      ${(props) => props.theme.font.display2}
    }
    h2 {
      ${(props) => props.theme.font.display3}
    }
    h3 {
      ${(props) => props.theme.font.headline1}
    }
    h4 {
      ${(props) => props.theme.font.headline2}
    }
    h5 {
      ${(props) => props.theme.font.headline3}
    }
    h6 {
      ${(props) => props.theme.font.headline4}
    }
    .article h1 {
      ${(props) => desktop(props.theme.font.headline1)}
      ${(props) => tabletDown(props.theme.font.headline2)}
    }
    .article h2 {
      ${(props) => desktop(props.theme.font.headline1_b)}
      ${(props) => tabletDown(props.theme.font.headline3)}
    }
    .article h3 {
      ${(props) => desktop(props.theme.font.headline1_c)}
      ${(props) => tabletDown(props.theme.font.headline4)}
    }
    .article h4 {
      ${(props) => desktop(props.theme.font.headline2)}
      ${(props) => tabletDown(props.theme.font.headline4_a)}
    }
    .article h5 {
      ${(props) => desktop(props.theme.font.headline3)}
      ${(props) => tabletDown(props.theme.font.headline4_b)}
    }
    .article h6 {
      ${(props) => desktop(props.theme.font.headline4)}
      ${(props) => tabletDown(props.theme.font.headline4_c)}
    }
    span {
      ${(props) => props.theme.font.body}
    }
    p {
      ${(props) => props.theme.font.body}
    }
    a {
      ${(props) => props.theme.font.body}
      text-decoration: none;
      text-transform: none;
      color: ${(props) => props.theme.colors["text/primary"]};
    }
    li {
      ${(props) => props.theme.font.body}
      margin-bottom: 1rem;
    }
    button {
      border-radius: 3px;
      margin-top: 0.5rem;
      padding: 0 0.5rem;
    }

    button {
      color: ${(props) => props.theme.colors["text/primary"]};
    }

    input {
      border-radius: 3px;
      margin-top: 0.5rem;
      padding: 0.2rem 0.5rem;
    }
  }
`;

export const GlobalStyle = createGlobalStyle<{
  backgroundColor?: string | null;
}>`
  @supports (font: -apple-system-body) and (-webkit-appearance: none) { img[loading="lazy"] { clip-path: inset(0.6px) } }
  *{
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    box-sizing: border-box;
  }

  html {
    height: 100vh; 
    width: 100vw; 
    max-height: 100vh; 
    max-width: 100vw; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    background-color: ${(t) =>
      t.backgroundColor
        ? t.theme.colors[t.backgroundColor as keyof typeof t.theme.colors]
        : "unset"};
    scroll-behavior: smooth !important;
    position: absolute;
  }

  body{
    padding: 0;
    margin: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    height: 0px;
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  
  canvas {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
  }
  
  p::-moz-selection { background-color: lightgrey}
  p::selection { background-color: lightgrey; }
  input::-moz-selection { background-color: lightgrey}
  input::selection { background-color: lightgrey; }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${fonts}
`;
