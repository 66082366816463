import styled, { css, FlattenSimpleInterpolation } from "styled-components";

const mobileBreakpoint = 520;
const tabletBreakpoint = 912;

const mobileLayout = (children: FlattenSimpleInterpolation, not?: boolean) =>
  not
    ? css`
        @media (min-width: ${mobileBreakpoint + 1}px) {
          ${children}
        }
      `
    : css`
        @media (max-width: ${mobileBreakpoint}px) {
          ${children}
        }
      `;

const tabletLayout = (children: FlattenSimpleInterpolation, not?: boolean) =>
  not
    ? css`
        @media (max-width: ${mobileBreakpoint}px),
          (min-width: ${tabletBreakpoint + 1}px) {
          ${children}
        }
      `
    : css`
        @media (min-width: ${mobileBreakpoint +
          1}px) and (max-width: ${tabletBreakpoint}px) {
          ${children}
        }
      `;

const desktopLayout = (children: FlattenSimpleInterpolation, not?: boolean) =>
  not
    ? css`
        @media (max-width: ${tabletBreakpoint}px) {
          ${children}
        }
      `
    : css`
        @media (min-width: ${tabletBreakpoint + 1}px) {
          ${children}
        }
      `;

const tabletUpLayout = (children: FlattenSimpleInterpolation, not?: boolean) =>
  not
    ? css`
        @media (max-width: ${mobileBreakpoint}px) {
          ${children}
        }
      `
    : css`
        @media (min-width: ${mobileBreakpoint + 1}px) {
          ${children}
        }
      `;

const tabletDownLayout = (
  children: FlattenSimpleInterpolation,
  not?: boolean
) =>
  not
    ? css`
        @media (min-width: ${tabletBreakpoint + 1}px) {
          ${children}
        }
      `
    : css`
        @media (max-width: ${tabletBreakpoint}px) {
          ${children}
        }
      `;
/// Components

export const MobileOnly = styled.div`
  display: contents;
  position: relative;
  ${mobileLayout(
    css`
      display: none;
    `,
    true
  )}
`;

export const TabletOnly = styled.div`
  display: contents;
  position: relative;
  ${tabletLayout(
    css`
      display: none;
    `,
    true
  )}
`;

export const TabletUp = styled.div`
  display: contents;
  position: relative;
  ${tabletUpLayout(
    css`
      display: none;
    `,
    true
  )}
`;

export const TabletDown = styled.div`
  display: contents;
  position: relative;
  ${tabletDownLayout(
    css`
      display: none;
    `,
    true
  )}
`;

export const DesktopOnly = styled.div`
  display: contents;
  position: relative;
  ${desktopLayout(
    css`
      display: none;
    `,
    true
  )}
`;

/// Interpolations

export function mobile(children: FlattenSimpleInterpolation) {
  return css`
    ${mobileLayout(children)}
  `;
}

export function tablet(children: FlattenSimpleInterpolation) {
  return css`
    ${tabletLayout(children)}
  `;
}

export function tabletUp(children: FlattenSimpleInterpolation) {
  return css`
    ${tabletUpLayout(children)}
  `;
}

export function tabletDown(children: FlattenSimpleInterpolation) {
  return css`
    ${tabletDownLayout(children)}
  `;
}

export function desktop(children: FlattenSimpleInterpolation) {
  return css`
    ${desktopLayout(children)}
  `;
}

export function sizes(mobile: string, tablet: string, desktop: string) {
  return `(max-width: ${mobileBreakpoint}px) ${mobile}, (max-width: ${tabletBreakpoint}px) ${tablet}, ${desktop}`;
}

export type PositioningSettings = {
  marginTop?: string | null;
  marginBottom?: string | null;
};

export type PositioningParams = {
  desktop?: PositioningSettings | null;
  tablet?: PositioningSettings | null;
  mobile?: PositioningSettings | null;
};

export type BackgroundPositioningSettings = {
  position?: string | null;
};

export type BackgroundPositioningParams = {
  desktop?: BackgroundPositioningSettings | null;
  tablet?: BackgroundPositioningSettings | null;
  mobile?: BackgroundPositioningSettings | null;
};

export const position = (positioning: PositioningParams) =>
  css`
    ${desktop(
      css`
        margin-top: ${positioning?.desktop?.marginTop || "inherit"};
        margin-bottom: ${positioning?.desktop?.marginBottom || "inherit"};
      `
    )}
    ${positioning?.tablet &&
    tablet(
      css`
        margin-top: ${positioning?.tablet?.marginTop || "inherit"};
        margin-bottom: ${positioning?.tablet?.marginBottom || "inherit"};
      `
    )}
${positioning?.mobile &&
    mobile(
      css`
        margin-top: ${positioning?.mobile?.marginTop || "inherit"};
        margin-bottom: ${positioning?.mobile?.marginBottom || "inherit"};
      `
    )}
  `;
