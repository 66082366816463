import { Mode } from "./theme"
import { Colors, Mapped } from "./types"

export const colorDictionary = {
  'light-mode':{
    text:{
      primary: '#2E2B26',
      secondary: '#848484'
    },
    elements:{
      'gray-1': '#D6D6D6',
      'gray-2': '#C3C2C1',
      'gray-3': '#848484'
    },
    background:{
      'bg-0': '#D6D6D6',
      'bg-1': '#F0F0F0',
      'bg-2': '#E6E6E6'
    }
  }, 
  'dark-mode':{
    text:{
      primary: '#FFFFFF',
      secondary: '#ABABAB'
    },
    elements:{
      'gray-1': '#6B6B6B',
      'gray-2': '#808080',
      'gray-3': '#ABABAD'
    },
    background:{
      'bg-0': '#2E2E2E',
      'bg-1': '#424242',
      'bg-2': '#383838'
    }
  }
} as const

export const colors = (mode:Mode='dark-mode')=>MapKeys(colorDictionary[mode])

function MapKeys(categories: Colors):{[Key in Mapped]:string} {
  
  return Object.entries(categories).flatMap(([category, dict])=>{
    return Object.entries(dict as {readonly [key:string]:any}).flatMap(([key,value])=>({
      [`${category}/${key}`]: value
    }))
  }).reduce((agg,v)=>{
    const [key,value] = Object.entries(v)[0]
    return { ...agg, [key]: value }
  },{})
}
