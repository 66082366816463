import { PageBlocksHero8 } from "tina/__generated__/types";

export function getFilters(
  props: Omit<NonNullable<PageBlocksHero8["filters"]>, "__typename">
) {
  return Object.entries(props || {})
    .filter(([key, value]) => key !== "__typename" && value)
    .reduce(
      (agg, [key, value]) =>
        agg +
        (key === "hueRotate"
          ? `hue-rotate(${value}deg) `
          : key === "blur"
          ? `blur(${value}px) `
          : `${key}(${value}%) `),
      ""
    );
}
